import DatePicker from "react-datepicker";
import { useField } from "formik";
import React, { useState } from 'react';
import _ from 'lodash';

const FormikDateInput = ({...props}) => {
    const prefix = 've-date-input';
    const [field, meta, helpers] = useField(props.name);
    const [inputId] = useState(_.uniqueId(prefix));

    const {value: inputValue} = meta;
    const value = (inputValue && new Date(+inputValue)) || null;
    const {setValue} = helpers;

    const handleSetValue = (date: Date | null) => {
        setValue(date);

        if ("sendDate" in props) {
            props.sendDate(date, props.name);
        }
    }

    const setPopperLength = () => {
        const popper = document.getElementsByClassName(`${inputId}-popper`)[0];
        if (!popper) {
            return;
        }
        const input = document.getElementsByClassName(inputId)[0] as HTMLElement;
        const popperContainer = popper.getElementsByClassName('react-datepicker__time-container ')[0] as HTMLElement;
        const popperContent = popper.getElementsByClassName('react-datepicker__time-box')[0] as HTMLElement;

        if (input && popperContainer && popperContent) {
            popperContainer.style.width = `${input.offsetWidth}px`;
            popperContent.style.width = `${input.offsetWidth}px`;
        }
    }

    return (
        <div className={`${!props.noLabel && 'py-1'}`}>
            {!props.noLabel && (
                <div className={'input-label-wrapper'}>
                    {!!value && !!props.placeholderText && <div className="input-label">{props.placeholderText}</div>}
                </div>
            )}
            <DatePicker
                {...field}
                {...props}
                selected={value}
                onCalendarOpen={setPopperLength}
                onChange={(date) => handleSetValue(date)}
                popperClassName={`${inputId}-popper`}
                // isClearable
                dateFormat={props.dateFormat || 'yyy-MM-dd'}
                className={`${props.class} ${inputId}`}
            />
        </div>
    );
};

export default FormikDateInput;
