import React, {FC, useEffect, useState} from "react";
import usePagination from "../../../helpers/UsePagination";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {Container} from "react-bootstrap";
import {Form, Formik} from "formik";
import {handleOnFiltersChange} from "../../../helpers/Filters";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import DefaultSpinner from "../../../ui/Spinners/DefaultSpinner/DefaultSpinner";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {StockTypes} from "../../../enums/Skybox/StockTypes";
import {getCurrencySymbol} from "../../../helpers/CurrencyConverter";
import {Inventory} from "../../../models/Inventory";
import {getInventoriesForExcel, getPurchasedInventories} from "../../../@api/Inventory";
import {EventTypes} from "../../../enums/Skybox/EventTypes";
import './PurchasedInventoryPage.scss';
import AppDatePicker from "../../../ui/Inputs/AppDatePicker/AppDatePicker";
import * as XLSX from 'xlsx';
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import FiltersFormButtons from "../../../components/Forms/FiltersFormButtons";

interface FilterProps {
    from_date: Date | string | undefined;
    to_date: Date | string | undefined;
    sort_by: string;
    id: string;
    vendor: string;
    performer: string;
    section: string;
    row: string;
    external_reference: string;
    internal_notes: string;
    event_name: string;
    venue: string;
    public_notes: string;
}

const PurchasedInventoryPage: FC = () => {
    const tableHeaders = [
        'P.O. number',
        'P.O. date',
        'Credit card group',
        'CC last digits',
        'Vendor',
        'Created by',
        'External reference',
        'Payment status',
        'Payment method',
        'Performer',
        'Event name',
        'Event type',
        'Category',
        'Event date',
        'Venue',
        'Stock type',
        'In-hand date',
        'Section',
        'Row',
        'Seats',
        'QTY',
        'Unit cost',
        'Group cost',
        'Zone seating',
        'Co-operative',
        'Consignment',
        'Received',
        'Internal notes',
        'Status',
    ];

    const filterInitial: FilterProps = {
        from_date: undefined,
        to_date: undefined,
        sort_by: 'ASC',
        id: '',
        vendor: '',
        performer: '',
        section: '',
        row: '',
        external_reference: '',
        internal_notes: '',
        event_name: '',
        venue: '',
        public_notes: ''
    };

    const firstSearchInputGroup = [
        {
            key: 'id',
            name: 'id',
            placeholder: 'Search by P.O. ID',
        },
        {
            key: 'vendor',
            name: 'vendor',
            placeholder: 'Search by Vendor',
        },
        {
            key: 'performer',
            name: 'performer',
            placeholder: 'Search by Performer',
        },
        {
            key: 'section',
            name: 'section',
            placeholder: 'Search by Section',
        },
        {
            key: 'row',
            name: 'row',
            placeholder: 'Search by Row',
        },
    ];

    const secondSearchInputGroup = [
        {
            key: 'public_notes',
            name: 'public_notes',
            placeholder: 'Search by Public notes',
        },
        {
            key: 'external_reference',
            name: 'external_reference',
            placeholder: 'Search by External ref.',
        },
        {
            key: 'internal_notes',
            name: 'internal_notes',
            placeholder: 'Search by Internal notes',
        },
        {
            key: 'event_name',
            name: 'event_name',
            placeholder: 'Search by Event name',
        },
        {
            key: 'venue',
            name: 'venue',
            placeholder: 'Search by Event venue',
        },
    ];

    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const pagination = usePagination<Inventory>(getPurchasedInventories, filterInitial);

    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const [purchasedInventories, setPurchasedInventories] = useState<Inventory[] | []>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getPurchasedInventoryData = async () => {
        setIsLoading(true);
        const res = await getInventoriesForExcel();
        setIsLoading(false);
        setPurchasedInventories(res.data.inventories)
    };

    const exportToExcel = async () => {
        const headers = tableHeaders;
        const data = purchasedInventories.map((inventory) => [
            inventory.purchase_orders?.id,
            dateToLocaleString(inventory.purchase_orders?.created_at),
            '-',
            '-',
            inventory.purchase_orders?.user_vendor?.display_name,
            inventory.sb_vendor?.name,
            inventory.purchase_orders?.external_reference || '-',
            '-',
            '-',
            inventory.sb_event?.sb_performer?.name,
            inventory.sb_event?.name,
            EventTypes[inventory.sb_event?.sb_performer?.category_type as keyof typeof EventTypes],
            inventory.sb_event?.sb_performer?.category_name,
            dateToLocaleString(inventory.sb_event?.occurs_at),
            inventory.sb_event?.venue,
            StockTypes[inventory.stock_type as keyof typeof StockTypes],
            dateToLocaleString(inventory.in_hand_date),
            inventory.section,
            inventory.row,
            `${inventory.low_seat} - ${inventory.high_seat}`,
            inventory.quantity,
            `${getCurrencySymbol(inventory.currency_symbol, currencies)}${Math.ceil(inventory.unit_amount)} `,
            `${getCurrencySymbol(inventory.currency_symbol, currencies)}${inventory.unit_amount * inventory.quantity}`,
            '-',
            '-',
            inventory.consignment,
            '-',
            inventory.internal_notes || '-',
            '-'
        ]);

        const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

        ws['!cols'] = headers.map((header) => {
            let width = 20;
            if (header === 'P.O. number' || header === 'QTY') {
                width = 10;
            } else if (header === 'P.O. date' || header === 'Performer') {
                width = 30;
            } else if (header === 'Event name' || header === 'Venue') {
                width = 40;
            }
            return {wch: width}
        });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

        const filename = 'Purchased inventories.xlsx';
        XLSX.writeFile(wb, filename);
    };

    useEffect(() => {
        getPurchasedInventoryData();
    }, []);

    return (
        <Container className={'admin-users-page events'}>
            <div className={'purchased-header-container'}>
                <h1 className={'admin-users-page__title'}>Purchased Inventory</h1>
                <div onClick={exportToExcel}>
                    <AppButton disabled={isLoading} text={'Export to Excel'} color={'red'}/>
                </div>
            </div>
            <div className="mb-3">
                <Formik
                    enableReinitialize={true}
                    initialValues={pagination.filterValues}
                    onSubmit={async (values) => {
                        pagination.setFilterValues(values);
                    }}
                >
                    {({submitForm, resetForm, isSubmitting}) => {
                        return (
                            <Form className="table__filters"
                                  onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                <div className={'table__filters__wrapper'}>
                                    {
                                        firstSearchInputGroup.map((input, index) => (
                                            <div className="input-width"
                                                 key={input.name + index}>
                                                <DefaultFormikInput
                                                    key={input.key}
                                                    name={input.name}
                                                    placeholder={input.placeholder}
                                                    autocomplete={'off'}
                                                    class={'filters-search'}
                                                ></DefaultFormikInput>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={'table__filters__wrapper'}>
                                    {
                                        secondSearchInputGroup.map((input, index) => (
                                            <div className="input-width"
                                                 key={input.name + index}>
                                                <DefaultFormikInput
                                                    key={input.key}
                                                    name={input.name}
                                                    placeholder={input.placeholder}
                                                    autocomplete={'off'}
                                                    class={'filters-search'}
                                                ></DefaultFormikInput>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={'table__filters__wrapper justify-content-between'}>
                                    <div className={'flex-row flex-shrink-0'}>
                                        <AppDatePicker name={'from_date'} placeholder={'Date from'}/>
                                        <AppDatePicker name={'to_date'} placeholder={'Date to'}/>
                                    </div>
                                    <div className={'col-3'}>
                                        <FiltersFormButtons
                                            filterInitial={filterInitial}
                                            pagination={pagination}
                                            submitForm={submitForm}
                                            resetForm={resetForm}
                                            isSubmitting={isSubmitting}
                                            className={''}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <AppTable columns={tableHeaders} pagination={pagination}>
                {pagination.isLoading
                    ?
                    <tr>
                        <td className={'no-border-td'} colSpan={1000}>
                            <DefaultSpinner/>
                        </td>
                    </tr>
                    : <>
                        {pagination.items.length > 0 && pagination.items.map((inventory, index) => (
                            <tr data-row={index} key={inventory.id + index}>
                                <td>{inventory.purchase_orders?.id}</td>
                                <td>{dateToLocaleString(inventory.purchase_orders?.created_at)}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{inventory.purchase_orders?.user_vendor?.display_name}</td>
                                <td>{inventory.sb_vendor?.name}</td>
                                <td>{inventory.purchase_orders?.external_reference || '-'}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{inventory.sb_event?.sb_performer?.name}</td>
                                <td>{inventory.sb_event?.name}</td>
                                <td>{EventTypes[inventory.sb_event?.sb_performer?.category_type as keyof typeof EventTypes]}</td>
                                <td>{inventory.sb_event?.sb_performer?.category_name}</td>
                                <td>{dateToLocaleString(inventory.sb_event?.occurs_at)}</td>
                                <td>{inventory.sb_event?.venue}</td>
                                <td>{StockTypes[inventory.stock_type as keyof typeof StockTypes]}</td>
                                <td>{dateToLocaleString(inventory.in_hand_date)}</td>
                                <td>{inventory.section}</td>
                                <td>{inventory.row}</td>
                                <td>{inventory.low_seat}-{inventory.high_seat}</td>
                                <td>{inventory.quantity}</td>
                                <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{Math.ceil(inventory.unit_amount)}</td>
                                <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{inventory.unit_amount * inventory.quantity}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{inventory.consignment}</td>
                                <td>-</td>
                                <td>{inventory.internal_notes || '-'}</td>
                                <td>-</td>
                            </tr>
                        ))}
                    </>
                }
            </AppTable>
        </Container>
    )
}

export default PurchasedInventoryPage;
