import React, {FC, useEffect, useState} from 'react';
import {Container, Dropdown} from 'react-bootstrap';
import './UsersPage.scss';
import ActionIconDark from '../../../assets/images/other/action-icon-dark.png';
import BlockIcon from '../../../assets/images/admin/block-icon.png';
import CheckIcon from '../../../assets/images/admin/check-icon.png';
import TrashIcon from '../../../assets/images/admin/trash-icon.png';
import UnlockIcon from '../../../assets/images/admin/unlock-icon.png';
import SettingsIcon from '../../../assets/images/other/settingIcon.svg';
import UserIcon from '../../../assets/images/other/userIconDark.svg';
import {deleteUserRequest, updateUserStatusRequest, usersPaginateRequest} from '../../../@api/User';
import usePagination from '../../../helpers/UsePagination';
import {User} from '../../../models/User';
import {HttpSuccessResponse} from '../../../@api/Responses/HttpSuccessResponse';
import {UserStatusesEnum} from '../../../enums/UserStatusesEnum';
import {Link, useNavigate} from "react-router-dom";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {Field, Form, Formik, FormikState} from "formik";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {getPools} from "../../../@api/Pools";
import {CountryTypes} from "../../../enums/Skybox/CountryTypes";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {citiesNotPaginated} from "../../../@api/Vendor";
import {loginRequest} from "../../../@api/Auth";
import {login} from "../../../store/Reducers/Auth";
import {useDispatch} from "react-redux";
import {Pool} from "../../../models/Pool";
import FiltersFormButtons from "../../../components/Forms/FiltersFormButtons";

interface FilterProps {
    name: string;
    lastName: string;
    city: string;
    vendorId: string;
    status: string;
    country: string;
    pool: string;
}

const UsersPage: FC = () => {
    const tableHeaders = [
        '#',
        'Full Name',
        'Mobile',
        'Email',
        'Action',
    ];

    const filterInitial: FilterProps = {
        name: '',
        lastName: '',
        city: '',
        vendorId: '',
        status: '',
        country: '',
        pool: '',
    };

    const [pools, setPools] = useState<Pool[]>([]);

    const pagination = usePagination<User>(usersPaginateRequest, filterInitial);

    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    useEffect(() => {
        getPools().then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setPools(res.data);
            }
        });

        pagination.paginate();
    }, []);

    const updateUserStatus = async (userId: number, status: UserStatusesEnum) => {
        updateUserStatusRequest(userId, status).then((res) => {
            if (res instanceof HttpFailureResponse) {
                alert(res.message);
            }
        });
    };

    const deleteUser = async (userId: number) => {
        deleteUserRequest(userId).then((res) => {
            if (res instanceof HttpSuccessResponse) {
                pagination.paginate();
            } else {
                alert(res.message);
            }
        });
    };

    const loginAsUser = (user: User) => {
        loginRequest({email: user.email, password: '123'})
            .then((response) => {
                if (response instanceof HttpSuccessResponse) {
                    dispatch(login({
                        user: response.data.user,
                        authToken: response.data.auth_token,
                        pools: response.data.user.pools,
                        hidden_users: response.data.user.hidden_users,
                        settings: response.data.user.settings
                    }));

                    navigateTo('/user');
                }
            });
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Users</h1>

            <div className="mb-3">
                <Formik
                    enableReinitialize={true}
                    initialValues={pagination.filterValues as FilterProps}
                    onSubmit={async (values: FilterProps) => pagination.setFilterValues(values)}
                >
                    {({resetForm, submitForm, isSubmitting}) => {
                        return (
                            <Form className="table__filters">
                                <div className={"table__filters__wrapper-column"}>
                                    <div className="table__filters__wrapper">
                                        <DefaultFormikInput
                                            name={'name'}
                                            placeholder={'Search users by name'}
                                            autocomplete={'off'}
                                            class={'mb-2'}
                                        ></DefaultFormikInput>
                                        <DefaultFormikInput
                                            name={'lastName'}
                                            placeholder={'Search users by last name'}
                                            autocomplete={'off'}
                                            class={'mb-2'}
                                        ></DefaultFormikInput>
                                        <AppSmartSearch
                                            name={'city'}
                                            placeholder={'Search by city'}
                                            requestFunction={citiesNotPaginated}
                                            valuesKey={'city'}
                                        />
                                        <DefaultFormikInput
                                            name={'vendorId'}
                                            placeholder={'Search users by vendor ID'}
                                            autocomplete={'off'}
                                            class={'mb-2'}
                                        ></DefaultFormikInput>
                                    </div>
                                    <div className="table__filters__wrapper">
                                        <Field name={'country'} as="select">
                                            <option value="">Country</option>
                                            {Object.entries(CountryTypes)
                                                .sort((a, b) =>
                                                    (a[1] > b[1]) ? 1 : ((b[1] > a[1]) ? -1 : 0))
                                                .map((key) => (
                                                    <option key={key[0]} value={key[0]}>{key[1]}</option>
                                                ))}
                                        </Field>
                                        <Field name={'pool'} as="select">
                                            <option value="">Pool</option>
                                            {pools.map((item) => (
                                                <option key={item.name} value={item.name}>{item.name}</option>
                                            ))}
                                        </Field>
                                        <Field name={'status'} as="select">
                                            <option value="">Status</option>
                                            <option value="1">On hold</option>
                                            <option value="2">Registered</option>
                                            <option value="3">Blocked</option>
                                        </Field>
                                        <FiltersFormButtons
                                            pagination={pagination}
                                            resetForm={(filters) => resetForm(filters as FormikState<FilterProps>)}
                                            isSubmitting={isSubmitting}
                                            filterInitial={filterInitial}
                                            submitForm={submitForm}
                                        ></FiltersFormButtons>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>

            <AppTable columns={tableHeaders} pagination={pagination} noRecordsMessage='No users found' >
                <>
                    {pagination.items.length ?
                        pagination.items?.map((user, index) => (
                            <tr key={index}>
                                    <td className={'main-color'}>{index + 1}</td>
                                    <td>{user.name}</td>
                                    <td>{user.formatted_phone_number}</td>
                                    <td>{user.email}</td>

                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle className="admin-users-page__action-button">
                                                <img src={ActionIconDark}
                                                     alt="action icon"
                                                     className="action-icon dropdown-toggle"
                                                     draggable="false"
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item className="admin-users-page__dropdown-item" as={Link}
                                                               to={'/admin/users/' + user.id}>
                                                    <img src={SettingsIcon}
                                                         alt="action icon"
                                                         draggable="false"
                                                         className="admin-users-page__action-icon"
                                                    />Edit
                                                </Dropdown.Item>

                                                <Dropdown.Item className="admin-users-page__dropdown-item"
                                                               onClick={() => loginAsUser(user)}
                                                >
                                                    <img src={UserIcon}
                                                         alt="action icon"
                                                         draggable="false"
                                                         className="admin-users-page__action-icon"
                                                    />Login
                                                </Dropdown.Item>

                                                <Dropdown.Item className="admin-users-page__dropdown-item"
                                                               onClick={() => updateUserStatus(user.id, UserStatusesEnum.Verified)}
                                                >
                                                    <img src={CheckIcon}
                                                         alt="action icon"
                                                         draggable="false"
                                                         className="admin-users-page__action-icon"
                                                    />Approve
                                                </Dropdown.Item>

                                                <Dropdown.Item className="admin-users-page__dropdown-item"
                                                               onClick={() => updateUserStatus(user.id, UserStatusesEnum.Block)}
                                                >
                                                    <img src={BlockIcon}
                                                         alt="action icon"
                                                         draggable="false"
                                                         className="admin-users-page__action-icon"
                                                    />Block
                                                </Dropdown.Item>

                                                <Dropdown.Item className="admin-users-page__dropdown-item"
                                                               onClick={() => updateUserStatus(user.id, UserStatusesEnum.Verified)}
                                                >
                                                    <img src={UnlockIcon}
                                                         alt="action icon"
                                                         draggable="false"
                                                         className="admin-users-page__action-icon"
                                                    />Unlock
                                                </Dropdown.Item>

                                                <Dropdown.Item className="admin-users-page__dropdown-item"
                                                               onClick={() => deleteUser(user.id)}>
                                                    <img src={TrashIcon}
                                                         alt="action icon"
                                                         draggable="false"
                                                         className="admin-users-page__action-icon"
                                                    />Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                        )) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>
                    }
                </>
            </AppTable>
        </Container>
    );
};

export default UsersPage;
