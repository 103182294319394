import axios from "axios";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {P1CategorySeries} from "../models/P1/P1CategorySeries";
const urlPrefix = 'v2/p1_series';

export const getSeries = (categoryId?: number) => {
    return requestWrapper<GlobalStatusCodesEnum, P1CategorySeries[]>(
        axios.get(`${urlPrefix}`, categoryId ? {
            params: {
                category_id: categoryId
            }
        } : undefined)
    );
};
