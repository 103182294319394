import React, {FC} from "react";
import './InvoicesPage.scss';
import {Field, Form, Formik, FormikState} from "formik";
import {Container} from "react-bootstrap";
import usePagination from "../../../helpers/UsePagination";
import {Invoice} from "../../../models/Invoice";
import {invoicesPaginateRequest} from "../../../@api/Invoice";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {handleOnFiltersChange} from "../../../helpers/Filters";
import {RootState} from "../../../store/Store";
import {useSelector} from "react-redux";
import {PaymentMethods} from "../../../enums/PaymentMethods";
import {getCurrencySymbol} from "../../../helpers/CurrencyConverter";
import {IntegrationInvoicesEnum, IntegrationInvoicesTitleEnum} from "../../../enums/IntegrationInvoicesEnum";
import FiltersFormButtons from "../../../components/Forms/FiltersFormButtons";

interface FilterProps {
    date_from: string;
    date_to: string;
    sort_by: string;
    integration_type: string;
}

const InvoicesPage: FC = () => {
    const tableHeaders = [
        'Integration type',
        'ID',
        'PO ID',
        'Invoice Date',
        'Amount',
        'Quantity',
        'Seller email',
        'Customer email',
        'Customer name',
        'External Ref.',
        'Event Name',
        'Event Date',
        'Payment Method',
        'Fulfillment Status',
        'Received',
        'Public notes',
    ];

    const currencies = useSelector((state: RootState) => state.currency.currencies);

    const filterInitial: FilterProps = {
        date_from: '',
        date_to: '',
        sort_by: 'ASC',
        integration_type: IntegrationInvoicesEnum.ticombo
    };

    const dateFilters = [
        {name: 'date_from', placeholder: 'Date From'},
        {name: 'date_to', placeholder: 'Date To'},
    ];

    const pagination = usePagination<Invoice>(invoicesPaginateRequest, filterInitial);

    const integrationOptions = [
        {
            title: 'All services',
            value: IntegrationInvoicesEnum.ticombo + ','
        },
        {
            title: 'Ticombo',
            value: IntegrationInvoicesEnum.ticombo
        }
    ];

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Invoices</h1>
            <div className="mb-3">
                <Formik
                    enableReinitialize={true}
                    initialValues={pagination.filterValues as FilterProps}
                    onSubmit={async (values: FilterProps) => {
                        pagination.setFilterValues(values)
                    }}
                >
                    {({resetForm, submitForm, isSubmitting, values, setValues}) => {
                        return (
                            <Form
                                className="table__filters"
                                onSubmit={(event) => {
                                    handleOnFiltersChange(event, pagination.filterValues, pagination.setFilterValues)
                                }}>
                                <div className="table__filters__wrapper">
                                    <div className={'col'}>
                                        <Field name={'sort_by'} as="select">
                                            <option value="ASC">Sort by date ↓</option>
                                            <option value="DESC">Sort by date ↑</option>
                                        </Field>
                                    </div>
                                    <div className={'col'}>
                                        <Field name={'integration_type'} as="select">
                                            {
                                                integrationOptions.map((selectOption, index) => (
                                                    <option key={index + 'integration'}
                                                            value={selectOption.value}>{selectOption.title}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    {dateFilters.map((data, index) => (
                                        <div className={'col'} key={index + 'Dates'}>
                                            <FormikDateInput
                                                name={data.name}
                                                placeholderText={data.placeholder}
                                                showTimeSelect
                                                autoComplete={'off'}
                                                sendDate={(date: string, name: string) =>
                                                    setValues({...values, [name]: date})
                                                }
                                            />
                                        </div>
                                    ))}
                                    <FiltersFormButtons
                                        pagination={pagination}
                                        resetForm={(filters) => resetForm(filters as FormikState<FilterProps>)}
                                        isSubmitting={isSubmitting}
                                        filterInitial={filterInitial}
                                        submitForm={submitForm}
                                        applyLabel={'Apply'}
                                    ></FiltersFormButtons>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <AppTable columns={tableHeaders} pagination={pagination}>
                <>
                    {pagination.items.length ?
                        pagination.items.map((invoice, index) => (
                            <React.Fragment key={index}>
                                <tr className={"invoice-section-page__tr"} data-row={index}>
                                    <td>{IntegrationInvoicesTitleEnum[invoice.integration_type as keyof typeof IntegrationInvoicesTitleEnum]}</td>
                                    <td>{invoice.id}</td>
                                    <td>{invoice.sb_invoice?.purchase_id}</td>
                                    <td>{dateToLocaleString(invoice.date)}</td>
                                    <td>{getCurrencySymbol(invoice.currency_code, currencies)}{invoice.amount}</td>
                                    <td>{invoice.quantity}</td>
                                    <td>{invoice.owner?.email}</td>
                                    <td>{invoice.customer_email ? invoice.customer_email : invoice.customers?.email}</td>
                                    <td>{invoice.customer_name ? invoice.customer_name : invoice.customers?.display_name}</td>
                                    <td>{invoice.external_reference || invoice.sb_invoice?.external_reference}</td>
                                    <td>{invoice.event_name}</td>
                                    <td>{dateToLocaleString(invoice.event_date)}</td>
                                    <td>{invoice.customers ? PaymentMethods[invoice.customers.payment_method as keyof typeof PaymentMethods] : invoice.sb_invoice?.payment_method}</td>
                                    <td>{'Pending' || invoice.sb_invoice?.fulfillment_status}</td>
                                    <td>{'No' || invoice.sb_invoice?.received}</td>
                                    <td>{invoice.public_notes}</td>
                                </tr>
                            </React.Fragment>
                        )) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>
                    }
                </>
            </AppTable>
        </Container>
    );
}
export default InvoicesPage;
