export const dateToLocaleString = (date: string | null | undefined) => {
    if (!date) return '';

    return date ? new Date(date).toLocaleString() : '';
}

export const dateToLocaleDateString = (date: string) => {
    return date ? new Date(date).toLocaleDateString() : '';
}

export const getTimeWithoutSeconds = (date: string) => {
    if (!date) return '';

    const eventTimeDate = new Date(date);
    const eventHours = eventTimeDate.getHours();
    const eventMinutes = eventTimeDate.getMinutes();
    const eventAmPm = eventHours >= 12 ? 'PM' : 'AM';
    const eventHours12 = eventHours % 12 || 12;

    return `${eventHours12}:${eventMinutes < 10 ? '0' : ''}${eventMinutes} ${eventAmPm}`;
}

export const toDayStart = (date: Date)=> {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const excludeTime = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;

  return new Date(date.getTime() - excludeTime);
}

export const todayDate = () => {
  const noOffsetDate = new Date();
  const userTimezoneOffset = noOffsetDate.getTimezoneOffset() * 60000;

  return toDayStart(new Date(noOffsetDate.getTime() - userTimezoneOffset));
};
