import React, {FC, useState} from "react";
import './ViagogoInvoicesPage.scss';
import {Field, Form, Formik} from "formik";
import {Container} from "react-bootstrap";
import usePagination from "../../../helpers/UsePagination";
import {Invoice} from "../../../models/Invoice";
import {invoicesPaginateRequest} from "../../../@api/Invoice";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import {handleOnFiltersChange} from "../../../helpers/Filters";
import {RootState} from "../../../store/Store";
import {useSelector} from "react-redux";
import {getCurrencySymbol} from "../../../helpers/CurrencyConverter";
import {IntegrationInvoicesEnum} from "../../../enums/IntegrationInvoicesEnum";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import CreateInvoiceForViagogoForm from "./components/CreateInvoiceForViagogoForm/CreateInvoiceForViagogoForm";
import FiltersFormButtons from "../../../components/Forms/FiltersFormButtons";

interface FilterProps {
    date_from: string;
    date_to: string;
    sort_by: string;
    integration_type: string;
}

const ViagogoInvoicesPage: FC = () => {
    const tableHeaders = [
        'ID',
        'Invoice Date',
        'Unit amount',
        'Quantity',
        'Seller email',
        'Event Name',
        'Event Date',
    ];

    const currencies = useSelector((state: RootState) => state.currency.currencies);

    const filterInitial: FilterProps = {
        date_from: '',
        date_to: '',
        sort_by: 'ASC',
        integration_type: IntegrationInvoicesEnum.viagogo
    };

    const dateFilters = [
        {name: 'date_from', placeholder: 'Date From'},
        {name: 'date_to', placeholder: 'Date To'},
    ];


    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEditModal, setIsEditModal] = useState<boolean>(false);

    const pagination = usePagination<Invoice>(invoicesPaginateRequest, filterInitial);
    const {items: invoices, filterValues, setFilterValues} = pagination;

    const handleInvoiceModal = async () => {
        setIsModalOpen(!isModalOpen);
        await pagination.paginate(filterValues)
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Viagogo Invoices / Viagogo Sales</h1>
            <div style={{width: "fit-content"}} onClick={() => handleInvoiceModal()}>
                <AppButton text={'Add Invoice'}></AppButton>
            </div>
            <div className="mb-3 mt-3">
                <Formik
                    enableReinitialize={true}
                    initialValues={pagination.filterValues}
                    onSubmit={async (values) => {
                        pagination.setFilterValues(values);
                    }}
                >
                    {({resetForm, submitForm, isSubmitting, values, setValues}) => {
                        return (
                            <Form
                                className="table__filters"
                                onChange={(event) => {
                                    handleOnFiltersChange(event, filterValues, setFilterValues)
                                }}>
                                <div className="table__filters__wrapper">
                                    <Field name={'sort_by'} as="select">
                                        <option value="ASC">Sort by date ↓</option>
                                        <option value="DESC">Sort by date ↑</option>
                                    </Field>
                                    {dateFilters.map((data, index) => (
                                        <FormikDateInput
                                            key={index + 'Dates'}
                                            name={data.name}
                                            placeholderText={data.placeholder}
                                            showTimeSelect
                                            autoComplete={'off'}
                                            sendDate={(date: string, name: string) =>
                                                setValues({...values, [name]: date})
                                            }
                                        />
                                    ))}
                                    <FiltersFormButtons
                                        filterInitial={filterInitial}
                                        pagination={pagination}
                                        submitForm={submitForm}
                                        resetForm={resetForm}
                                        isSubmitting={isSubmitting}
                                    />
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <AppTable columns={tableHeaders} pagination={pagination}>
                <>
                    {invoices.length ?
                        invoices.map((invoice, index) => (
                            <React.Fragment key={index}>
                                <tr className={"invoice-section-page__tr"} data-row={index}>
                                    <td>{invoice.id}</td>
                                    <td>{dateToLocaleString(invoice.date)}</td>
                                    <td>{getCurrencySymbol(invoice.currency_code, currencies)}{invoice.full_unit_amount}</td>
                                    <td>{invoice.quantity}</td>
                                    <td>{invoice.owner.email}</td>
                                    <td>{invoice.event_name}</td>
                                    <td>{dateToLocaleString(invoice.event_date)}</td>
                                </tr>
                            </React.Fragment>
                        )) :
                        <tr>
                            <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>No results</td>
                        </tr>
                    }
                </>
            </AppTable>

            <AppModal
                customClass={'customer-modal'}
                centered={true}
                backdrop={'static'}
                keyboard={false}
                title={isEditModal ? 'Edit invoice' : 'Create new invoice'}
                form={
                    <CreateInvoiceForViagogoForm handleClose={handleInvoiceModal}/>
                }
                show={isModalOpen}
                showHandle={handleInvoiceModal}
                onClose={() => handleInvoiceModal()}
            />
        </Container>
    );
}
export default ViagogoInvoicesPage;
