import EventsComponent from "../../../../components/EventsPage/EventsComponent/EventsComponent";
import React, {useEffect} from "react";
import {EventPageFilters} from "../EventsPage";
import usePagination from "../../../../helpers/UsePagination";
import {P1Event} from "../../../../models/Event";
import {todayDate} from "../../../../helpers/DateFormatter";
import P1EventsTable from "../../../../components/EventsPage/P1EventsTable/P1EventsTable";
import P1EventCategoryFilter from "../components/P1EventCategoryFilter/P1EventCategoryFilter";
import {p1EventsPaginateRequest} from "../../../../@api/P1Event";
import {EventsPageType} from "../../../../enums/EventsPageTypeEnum";

const P1EventsPage = () => {

  const filterInitial: EventPageFilters = {
    id: '',
    name: '',
    venue: '',
    city: '',
    category_id: 0,
    series: '',
    operator: '',
    from_date: todayDate(),
    to_date: null,
  };

  const pagination = usePagination<P1Event>(p1EventsPaginateRequest, filterInitial);

  const formValues = [
    [
      {key: "id", value: "Event ID"},
      {key: "name", value: "Event Name"},
      {key: "venue", value: "Event Venue"},
      {key: "city", value: "Event City"}
    ]
  ];

  useEffect(() => {
    pagination.reset();
  }, []);

  return (
      <EventsComponent
          key={`events-component-${EventsPageType.P1}`}
          pagination={pagination}
          filterInitial={filterInitial}
          formValues={formValues}
          tableComponent={
            <P1EventsTable
                key={`events-component-${EventsPageType.P1}-table`}
                pagination={pagination}
            />
          }
          pageType={EventsPageType.P1}
          customFilters={(formChange) => (
              <P1EventCategoryFilter
                  filters={pagination.filterValues as EventPageFilters}
                  onFormChange={formChange}/>
          )}
          wrapDates={true}
      />
  )
}

export default P1EventsPage;
