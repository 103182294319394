import React, {useState} from "react";
import {Form, Formik, FormikValues} from "formik";
import DefaultFormikInput from "../../Inputs/DefaultFormikInput/DefaultFormikInput";
import FormikDateInput from "../../Inputs/FormikDateInput/FormikDateInput";
import './EventsComponent.scss';

import {Event, LTGEvent, P1Event, SbEvent} from "../../../models/Event";
import {TicomboEvent} from "../../../models/TicomboEvent";
import {Log} from "../../../models/Log";
import {AmountMapReport} from "../../../models/AmountMapReport";
import {AcMilanEvent} from "../../../models/AcMilanEvent";
import {FormikFormChange, FormikFormReset} from '../../../@interfaces/FormikHelpers';
import { EventPageFilters } from '../../../pages/Admin/EventsPage/EventsPage';
import {handleOnFiltersChange} from "../../../helpers/Filters";
import AppLoader from '../../../ui/Loader/AppLoader/AppLoader';
import {Pagination} from "../../../helpers/UsePagination";
import FiltersFormButtons from "../../Forms/FiltersFormButtons";
import {EventsPageType} from "../../../enums/EventsPageTypeEnum";
import {toDayStart} from "../../../helpers/DateFormatter";

export type CustomFiltersFn = (
    f?: FormikFormChange<EventPageFilters>,
    r?: FormikFormReset<EventPageFilters>,
    v?: FormikValues,
    s?: (values: React.SetStateAction<FormikValues>, shouldValidate?: boolean) => void
) => JSX.Element;

type Entity = any | Event | P1Event | LTGEvent | TicomboEvent | SbEvent | AcMilanEvent | Log | AmountMapReport;

interface EventsComponentProps {
    pagination: Pagination<Entity>;
    filterInitial: EventPageFilters;
    formValues: { key: string, value: string }[][];
    tableComponent: JSX.Element;
    pageType?: EventsPageType;
    customFilters?: CustomFiltersFn;
    bottomFilterPanel?: JSX.Element;
    topCustomFilters?: JSX.Element;
    wrapDates?: boolean;
}

const EventsComponent: React.FC<EventsComponentProps> = (
    {
        pagination,
        filterInitial,
        formValues,
        tableComponent,
        pageType,
        customFilters,
        bottomFilterPanel,
        topCustomFilters,
        wrapDates
    }
) => {

    const [filterValues, setFilterValues] = useState<FormikValues>(filterInitial);

    const handleOnDatesChange = (date: Date | null, name: string) => {
        if (name === 'to_date' && date !== null) {
            date.setTime(toDayStart(date).getTime() + (24 * 60 * 60 * 1000 - 1));
        }
        filterValues[name] = date;
        setFilterValues(filterValues);
    };

    const dates = () => {
        return (
            <>
                <div className="events-page__filters__component">
                    <FormikDateInput
                        name={'from_date'}
                        placeholderText={'From date'}
                        showTimeSelect={false}
                        autoComplete='off'
                        sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                    />
                </div>
                <div className="events-page__filters__component">
                    <FormikDateInput
                        name={'to_date'}
                        placeholderText={'To date'}
                        showTimeSelect={false}
                        autoComplete='off'
                        sendDate={(date: Date | null, name: string) => handleOnDatesChange(date, name)}
                    />
                </div>
            </>
        );
    };


    return (
        <div className="events-page">
            <div className="events-page__filters">
                <Formik
                    enableReinitialize={true}
                    initialValues={{...pagination.filterValues}}
                    onSubmit={async (values) => {
                        pagination.setFilterValues(values);
                    }}
                >
                    {({resetForm, values, setValues, submitForm, isSubmitting}) => {

                        const filterButtons = (
                            <FiltersFormButtons
                                pagination={pagination}
                                resetForm={resetForm}
                                isSubmitting={isSubmitting}
                                filterInitial={filterInitial}
                                submitForm={submitForm}
                                applyLabel={'Apply'}
                            ></FiltersFormButtons>
                        )

                        return (
                            <Form className="filters-form"
                                  onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                <div key={formValues[0].map(formField => formField.key).join('')}
                                     className="events-page__filters__wrapper">
                                    {formValues[0].map(formField => (
                                        <DefaultFormikInput
                                            key={formField.key}
                                            name={formField.key}
                                            placeholder={formField.value}
                                            autocomplete={'off'}
                                            class={'filters-search'}
                                        ></DefaultFormikInput>
                                    ))}
                                    {pageType === EventsPageType.SB ?
                                        <>
                                            {dates()}
                                            {formValues[1]?.map(formField => (
                                                <DefaultFormikInput
                                                    key={formField.key}
                                                    name={formField.key}
                                                    placeholder={formField.value}
                                                    autocomplete={'off'}
                                                    class={'filters-search'}
                                                ></DefaultFormikInput>
                                            ))}
                                            {topCustomFilters
                                                ? <>
                                                    {topCustomFilters}
                                                </>
                                                : <></>
                                            }
                                            {filterButtons}
                                        </> :
                                        <></>
                                    }
                                </div>
                                <div className="events-page__filters__wrapper">
                                    {customFilters ? customFilters(setFilterValues, resetForm, values, setValues) : null}
                                    {topCustomFilters
                                        ? <></>
                                        : <>
                                        {wrapDates ? <div className='d-flex col-auto gap-2'>{dates()}</div> : dates()}
                                            {pageType === EventsPageType.P1 ?
                                                <></> :
                                                <>{filterButtons}</>
                                            }
                                        </>
                                    }
                                    {pageType === EventsPageType.P1 ?
                                        <>{filterButtons}</> :
                                        <></>
                                    }
                                </div>
                                {bottomFilterPanel && (
                                    <div className="events-page__filters__wrapper">
                                        {bottomFilterPanel}
                                    </div>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {!pagination?.isLoading ? tableComponent : (
                <div className={'my-5'}>
                    <AppLoader />
                </div>
            )}
        </div>
    );
}

export default EventsComponent;
