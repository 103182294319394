import React from 'react';
import "./EditEventP1Categories.scss";
import AppToggle from '../../../../ui/Inputs/AppToggle/AppToggle';
import {Card} from 'react-bootstrap';
import {P1EventSeatingPlansMapping} from '../../../../models/Event';
import DefaultSpinner from '../../../../ui/Spinners/DefaultSpinner/DefaultSpinner';
import {Tooltip, Whisper} from "rsuite";
import AppSelect from "../../../../ui/Inputs/AppSelect/AppSelect";

interface EditEventP1CategoriesProps {
  p1CategoriesSeatingPlanMap: P1EventSeatingPlansMapping[];
  useSeatingPlan: boolean;
  useHotelPriceIncluded: boolean;
  isUpdating: boolean;
  selectedSeatingPlan?: string;
  handleSeatingPlanUsageChange: (useSeatingPlan: boolean) => void;
  handleHotelPriceIncludedChange: (priceIncluded: boolean) => void;
  seatingPlanSelected: (plan: P1EventSeatingPlansMapping) => void;
}

const EditEventP1Categories: React.FC<EditEventP1CategoriesProps> = ({
                                                                       p1CategoriesSeatingPlanMap,
                                                                       useSeatingPlan,
                                                                       useHotelPriceIncluded,
                                                                       selectedSeatingPlan,
                                                                       handleSeatingPlanUsageChange,
                                                                       handleHotelPriceIncludedChange,
                                                                       seatingPlanSelected,
                                                                       isUpdating
                                                                     }) => {

  const getCardClasses = (p1CategoriesSeatingPlan: P1EventSeatingPlansMapping): string => {
    const classes = ['event-seating-plan'];
    if (p1CategoriesSeatingPlan.sb_event_id) {
      classes.push('with-mapping')
    }
    if (p1CategoriesSeatingPlan.seating_plan === selectedSeatingPlan) {
      classes.push('selected')
    }

    return classes.join(' ');
  }

  if (isUpdating) {
    return (
        <div className={'event-seating-plans position-relative w-100 overflow-hidden'}>
          <div className={'col justify-content-start'}>
            <div className={'mt-3 mb-2'}>&nbsp;</div>
          </div>
          <div className={'options flex flex-nowrap overflow-auto flex-row justify-content-center'}>
            {[0, 1, 2, 3, 4].map(i => (
                <div className={'option-item col mb-2'} key={`placeholder-${i}`}>
                  <Card className={getCardClasses({seating_plan: ''})}>&nbsp;</Card>
                </div>
            ))}
          </div>
          <div className={'row loader-overlay'}>
            <DefaultSpinner size={'20px'} thickness={2}/>
          </div>
        </div>
    )
  }

  const hasP1CategoriesSeatingPlans = p1CategoriesSeatingPlanMap.length > 0;

  const noCategoriesTooltip = (
      <Tooltip>
        No categories available at the moment.
      </Tooltip>
  );

  const toggle = (
      <AppToggle label={'Map categories to events'}
                 className={'my-2 d-flex'}
                 id={'map-categories-to-event'}
                 value={useSeatingPlan}
                 dispatchValue={handleSeatingPlanUsageChange}
                 disabled={!hasP1CategoriesSeatingPlans && !useSeatingPlan}
      />
  );

  return (
      <div className={'event-seating-plans position-relative'}>
        <hr className={'my-1'}/>
        <div className={'col d-flex gap-3 justify-content-start pt-2'}>
          {!hasP1CategoriesSeatingPlans && !useSeatingPlan ? (
              <Whisper placement="autoHorizontal" controlId="control-id-hover" trigger="hover"
                       speaker={noCategoriesTooltip}>
                <div className={'float-start'}>{toggle}</div>
              </Whisper>
          ) : toggle}
          <div className={'w-auto'}>
            <AppSelect
                placeholder={'Price type'}
                value={useHotelPriceIncluded ? 'with' : 'without'}
                items={[
                  {name: 'without hotel', value: 'without'},
                  {name: 'with hotel', value: 'with'},
                ]} name={'price_type'} changeCallback={(type) => {
              handleHotelPriceIncludedChange(type === 'with')
            }}/>
          </div>
        </div>
        <div className={'options flex flex-nowrap flex-row' + (useSeatingPlan ? ' expanded' : '')}>
          {hasP1CategoriesSeatingPlans ? (
              <>
                {p1CategoriesSeatingPlanMap.map((seatingPlan, index) => (
                    <div className={'option-item col mb-2'} key={`p1-categories-tabs-${index}-${seatingPlan}`}>
                      <Card className={getCardClasses(seatingPlan)} onClick={() => {
                        (seatingPlan.seating_plan !== selectedSeatingPlan) && seatingPlanSelected(seatingPlan)
                      }}>
                        <div className={'additional-label'}><b>Mapped</b>&nbsp;</div>
                        <div className={'flex-grow-1 p-2'}>{seatingPlan.seating_plan}</div>
                      </Card>
                    </div>
                ))}
              </>
          ) : (
              <>
                No categories available
              </>
          )}
        </div>
        <hr className={'my-1'}/>
      </div>
  );
}

export default EditEventP1Categories;
