import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";
import {FormikValues} from "formik";
import {LTGCategory, LTGLocationCategory, P1Category} from "../models/Category";
import {PaginationParams} from "../@interfaces/PaginationParams";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import {IHttpSuccessResponse} from "./Responses/HttpSuccessResponse";
import {LtgAllocation} from "../models/LtgAllocation";

export const get = () => {
    return requestWrapper<GlobalStatusCodesEnum, P1Category[]>(
        axios.get('/p1_categories'),
    );
}

export const update = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, P1Category>(
        axios.patch('/p1_categories/' + id, params),
    );
}

export const ltgCategoriesRequest = () => {
    return requestWrapper<GlobalStatusCodesEnum, LTGCategory[]>(
        axios.get('/integrations/ltg/categories')
    );
}

export const ltgAllocationsRequest = () => {
  return requestWrapper<GlobalStatusCodesEnum, LtgAllocation[]>(
      axios.get('/integrations/ltg/extra-props/allocation')
  );
}

export const ltgLocationCategoriesPaginateRequest = (params: PaginationParams<LTGLocationCategory>) => {
    params.perPage = 500;

    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<LTGLocationCategory>>(
        axios.get('/integrations/ltg/location-categories', {
            params: params,
        })
    );
}

export const updateLTGLocationCategory = (id: number, data: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, IHttpSuccessResponse<GlobalStatusCodesEnum, LTGLocationCategory>>(
        axios.put(`/integrations/ltg/location-categories/${id}`, data),
    );
};
