import React, {FC, useState} from "react";
import usePagination from "../../../helpers/UsePagination";
import { Container} from "react-bootstrap";
import {Form, Formik} from "formik";
import {handleOnFiltersChange} from "../../../helpers/Filters";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import DefaultSpinner from "../../../ui/Spinners/DefaultSpinner/DefaultSpinner";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import {purchaseOrdersPaginateRequest} from "../../../@api/PurchaseOrders";
import {StockTypes} from "../../../enums/Skybox/StockTypes";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {getCurrencySymbol} from "../../../helpers/CurrencyConverter";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import {Link, useNavigate} from "react-router-dom";
import {Inventory} from "../../../models/Inventory";
import FiltersFormButtons from "../../../components/Forms/FiltersFormButtons";
import AppSelect from "../../../ui/Inputs/AppSelect/AppSelect";

interface FilterProps {
    from_date: Date | string | undefined;
    to_date: Date | string | undefined;
    id: string,
    vendor: string,
    delivery_method: string,
}

const PurchasesPage: FC = () => {
    const tableHeaders = [
        'P.O. number',
        'P.O. date',
        'Vendor',
        'Created by',
        'Shipping',
        'Payment method',
        'QTY',
        'Section',
        'Row',
        'Unit cost',
        'Total cost',
        'Currency',
        'Payment status',
        'Purchase status',
        'Consignment',
        'Received',
        'Actions'
    ];

    const deliveryMethods = Object.keys(StockTypes).map((option) => ({
        value: option,
        //@ts-ignore-next-line
        name: StockTypes[option]
    }));

    const filterInitial: FilterProps = {
        from_date: '',
        to_date: '',
        id: '',
        vendor: '',
        delivery_method: '',
    };

    const dateFilters = [
        {name: 'from_date', placeholder: 'Date From'},
        {name: 'to_date', placeholder: 'Date To'},
    ];

    const pagination = usePagination<Inventory>(purchaseOrdersPaginateRequest, filterInitial);
    const currencies = useSelector((state: RootState) => state.currency.currencies);
    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const navigate = useNavigate();

    const handleRowClick = (purchaseOrderId:number) => {
        const redirectUrl = `/user/sell/edit-purchase-order/${purchaseOrderId}`;
        navigate(redirectUrl);
    };

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Purchases</h1>
            <div className="mb-3">
                <Formik
                    enableReinitialize={true}
                    initialValues={pagination.filterValues}
                    onSubmit={async (values) => {
                        pagination.setFilterValues(values);
                    }}
                >
                    {({submitForm, isSubmitting, resetForm, values, setValues, setFieldValue}) => {
                        return (
                            <Form className="table__filters"
                                  onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                <div className="table__filters__wrapper">
                                    <div className={'flex-row flex-shrink-0'}>
                                        {dateFilters.map((data, index) => (
                                            <FormikDateInput
                                                key={index + 'Dates'}
                                                name={data.name}
                                                placeholderText={data.placeholder}
                                                showTimeSelect
                                                autoComplete={'off'}
                                                sendDate={(date: string, name: string) =>
                                                    setFieldValue(name, date)
                                                }
                                            />
                                        ))}
                                    </div>
                                    <DefaultFormikInput
                                        key={'id'}
                                        name={'id'}
                                        placeholder={'Search by P.O. ID'}
                                        autocomplete={'off'}
                                        class={'filters-search'}
                                    ></DefaultFormikInput>
                                    <DefaultFormikInput
                                        key={'vendor'}
                                        name={'vendor'}
                                        placeholder={'Search P.O. by Vendor'}
                                        autocomplete={'off'}
                                        class={'filters-search'}
                                    ></DefaultFormikInput>
                                    <AppSelect
                                        items={[
                                            {value: '', name: 'Any'},
                                            ...deliveryMethods
                                        ]}
                                        placeholder={'Delivery Method'}
                                        value={values.delivery_method}
                                        name={'delivery_method'}
                                    />
                                    <FiltersFormButtons
                                        filterInitial={filterInitial}
                                        pagination={pagination}
                                        submitForm={submitForm}
                                        resetForm={resetForm}
                                        isSubmitting={isSubmitting}
                                    />
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <AppTable columns={tableHeaders} pagination={pagination}>
                {pagination.isLoading
                    ?
                    <tr>
                        <td className={'no-border-td'} colSpan={1000}>
                            <DefaultSpinner/>
                        </td>
                    </tr>
                    :
                    <>{pagination.items.length ? (
                        pagination.items.filter(
                                (inventory, index, self) =>
                                index === self.findIndex((inv) => inv.purchase_order_id === inventory.purchase_order_id)
                            ).map((inventory, index) =>
                                <tr data-row={index} key={inventory.id + index}>
                                    <td onClick={() => handleRowClick(inventory.purchase_order_id)} className='clickable-td'>
                                        {inventory.purchase_order_id}
                                    </td>
                                    <td>{dateToLocaleString(inventory.purchase_orders?.created_at)}</td>
                                    <td>{inventory.purchase_orders?.user_vendor?.display_name}</td>
                                    <td>{inventory.purchase_orders?.user?.name}</td>
                                    <td>{StockTypes[inventory.stock_type as keyof typeof StockTypes]}</td>
                                    <td>-</td>
                                    <td>{inventory.quantity}</td>
                                    <td>{inventory.section}</td>
                                    <td>{inventory.row}</td>
                                    <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{Math.ceil(inventory.unit_amount)}</td>
                                    <td>{getCurrencySymbol(inventory.currency_symbol, currencies)}{inventory.unit_amount * inventory.quantity}</td>
                                    <td>{inventory.currency_symbol}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>{inventory.consignment}</td>
                                    <td>-</td>
                                    <td>
                                        <Link to={`/user/sell/edit-purchase-order/${inventory.purchase_order_id}`}>
                                            <AppButton text={'Edit P.O.'}/>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        )
                        :
                        null
                    }
                    </>}
            </AppTable>
        </Container>
    )
}

export default PurchasesPage;
