import EventsComponent from "../../../../components/EventsPage/EventsComponent/EventsComponent";
import React from "react";
import usePagination from "../../../../helpers/UsePagination";
import {todayDate} from "../../../../helpers/DateFormatter";
import AcMilanEventsTable from "../../../../components/EventsPage/AcMilanEvents/AcMilanEvents";
import {AcMilanEvent} from "../../../../models/AcMilanEvent";
import {acMilanEventsPaginateRequest} from "../../../../@api/Event";
import {Field} from "formik";
import {EventsPageType} from "../../../../enums/EventsPageTypeEnum";

const ACMilanEventsPage = () => {

  const filterInitial = {
    id: '',
    name: '',
    venue: '',
    city: '',
    operator: '=',
    from_date: todayDate(),
    to_date: null,
  };

  const pagination = usePagination<AcMilanEvent>(acMilanEventsPaginateRequest, filterInitial);

  const formValues = [
    [
      {key: "id", value: "Event ID"},
      {key: "name", value: "Event Name"},
      {key: "venue", value: "Event Venue"},
      {key: "city", value: "Event City"}
    ]
  ];

  const AcMilanEventsCustomFilter = () => {
    return (
        <>
          <div className="events-page__filters__component">
            <Field name={'operator'} as="select" className={'my-1'}
                   style={{display: 'block', backgroundColor: 'white'}}>

              <option key={'Unmapped'} value="=">Unmapped</option>
              <option key={'Mapped'} value="<>">Mapped</option>
            </Field>
          </div>
        </>
    )
  };

  return (
      <EventsComponent
          key={`events-component-${EventsPageType.ACMilan}__events`}
          pagination={pagination}
          filterInitial={filterInitial}
          formValues={formValues}
          tableComponent={
            <AcMilanEventsTable
                key={`events-component-${EventsPageType.ACMilan}__events-table`}
                pagination={pagination}
            />
          }
          customFilters={AcMilanEventsCustomFilter}
          wrapDates={true}
      />
  )
}

export default ACMilanEventsPage;
