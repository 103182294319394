import SbEventsTable from "../../../../components/EventsPage/SbEventsTable/SbEventsTable";
import EventsComponent, {CustomFiltersFn} from "../../../../components/EventsPage/EventsComponent/EventsComponent";
import React, {FC, useEffect} from "react";
import {EventPageFilters} from "../EventsPage";
import usePagination from "../../../../helpers/UsePagination";
import {SbEvent} from "../../../../models/Event";
import {sbEventsPaginateRequest} from "../../../../@api/Event";
import {todayDate} from "../../../../helpers/DateFormatter";
import {Field} from "formik";
import {Performer} from "../../../../models/Performer";
import AppSmartSearch from "../../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {categoriesNotPaginated, performersNotPaginated} from "../../../../@api/Performer";
import {EventsPageType} from "../../../../enums/EventsPageTypeEnum";
import AppCheckbox from "../../../../ui/Inputs/AppCheckbox/AppCheckbox";

const SkyboxEventsPage = () => {

  const formValues = [
    [
      {key: "id", value: "Event ID"},
      {key: "name", value: "Event Name"},
      {key: "venue", value: "Event Venue"},
    ],
    [
      {key: "city", value: "Event City"}
    ]
  ];

  const filterInitial = {
    id: '',
    name: '',
    venue: '',
    city: '',
    performer: '',
    mapped: '',
    category: '',
    from_date: todayDate(),
    to_date: null,
    with_inventories: false,
  };

  const pagination = usePagination<SbEvent>(sbEventsPaginateRequest, filterInitial);

  const eventsServices = [
    {name: 'Unmapped on ANY', value: '!'},
    {name: 'Unmapped on LTG', value: '!ltg'},
    {name: 'Unmapped on TC', value: '!tc'},
    {name: 'Unmapped on SH', value: '!sh'},
    {name: 'Mapped on ALL', value: '='},
    {name: 'Mapped on LTG', value: '=ltg'},
    {name: 'Mapped on TC', value: '=tc'},
    {name: 'Mapped on SH', value: '=sh'}
  ];

  const CategoriesFilter: CustomFiltersFn = (f, r, values, setValues) => {
    if (!values) {
      return <></>;
    }

    return (
        <div className="events-page__filters__component">
          <AppCheckbox label={'With Inventories only'} id={'with_inventories'} value={values.with_inventories === 'true'}
                       name={'with_inventories'} dispatchValue={
            (isChecked) => {
              setValues && setValues({
                ...values,
                with_inventories: isChecked ? 'true' : ''
              })
            }
          }/>
        </div>
    )
  };

  const TopCustomFilters: FC<{
    filterValues: EventPageFilters,
    setFilterValues: React.Dispatch<React.SetStateAction<any>>
  }> = ({filterValues, setFilterValues}) => {
    const handleCategoryChange = (item: Performer): void => {
      filterValues['category'] = item.name;
      setFilterValues(filterValues);
    };

    const handlePerformerChange = (item: Performer): void => {
      filterValues['performer'] = item.name;
      setFilterValues(filterValues);
    };

    return (
        <>
          <div className="events-page__filters__component">
            <AppSmartSearch
                name={'category'}
                placeholder={'Category'}
                requestFunction={categoriesNotPaginated}
                valuesKey={'name'}
                setDataFromRequest={handleCategoryChange}
            />
          </div>
          <div className="events-page__filters__component">
            <AppSmartSearch
                name={'performer'}
                placeholder={'Event Performer'}
                requestFunction={performersNotPaginated}
                valuesKey={'name'}
                setDataFromRequest={handlePerformerChange}
            />
          </div>
          <div className="events-page__filters__component">
            <Field name={'mapped'} as="select" className={'my-1'}>
              <option key={'emptyEventsServices'} value="">All Events</option>
              {
                eventsServices.map(eventsService => (
                    <option key={eventsService.name}
                            value={eventsService.value}>{eventsService.name}</option>
                ))
              }
            </Field>
          </div>
        </>
    );
  };

  useEffect(() => {
    pagination.reset();
  }, []);

  return (
      <EventsComponent
          key={`events-component-${EventsPageType.SB}`}
          pagination={pagination}
          filterInitial={filterInitial}
          formValues={formValues}
          tableComponent={
            <SbEventsTable
                key={`events-component-${EventsPageType.SB}-table`}
                pagination={pagination}
            />
          }
          pageType={EventsPageType.SB}
          customFilters={CategoriesFilter}
          topCustomFilters={
            <TopCustomFilters
                filterValues={pagination.filterValues as EventPageFilters}
                setFilterValues={pagination.setFilterValues}
            />
          }
      />
  )
}

export default SkyboxEventsPage;
