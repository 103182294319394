import {PaginationParams} from "../@interfaces/PaginationParams";
import {P1Event, P1EventSeatingPlansMapping} from "../models/Event";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import axios from "axios";
import {FormikValues} from "formik";
import {AuthResponseStatusesEnum} from "../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {RegistrationSuccessResponse} from "../@interfaces/Response/Auth";

const urlPrefix = '/p1_events'

export const p1EventsPaginateRequest = (params: PaginationParams<P1Event>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<P1Event>>(axios.get(`${urlPrefix}`, {
        params: params,
    }));
};

export const getP1Event = (p1Event: number) => {
    return requestWrapper<GlobalStatusCodesEnum, P1Event>(
        axios.get(`${urlPrefix}/${p1Event}`),
    );
}

export const patchP1Events = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.patch(`${urlPrefix}/${id}`, params),
    );
};

export const getP1EventSeatingPlans = (p1Event: number) => {
    return requestWrapper<GlobalStatusCodesEnum, Array<string>>(
        axios.get(`${urlPrefix}/${p1Event}/seating-plans`),
    );
}

export const getP1EventSeatingPlanMapping = (p1Event: number) => {
    return requestWrapper<GlobalStatusCodesEnum, Array<P1EventSeatingPlansMapping>>(
        axios.get(`${urlPrefix}/${p1Event}/seating-plans-mapping`),
    );
}

export const saveP1EventSeatingPlanMapping = (p1Event: number, mapping: P1EventSeatingPlansMapping) => {
    return requestWrapper<GlobalStatusCodesEnum, Array<P1EventSeatingPlansMapping>>(
        axios.post(`${urlPrefix}/${p1Event}/seating-plans-mapping`, mapping),
    );
}

export const deleteP1EventSeatingPlanMapping = (p1Event: number, mappingId: number) => {
    return requestWrapper<GlobalStatusCodesEnum>(
        axios.delete(`${urlPrefix}/${p1Event}/seating-plans-mapping/${mappingId}`),
    );
}

export const setP1EventSeatingPlanMappingUsage = (p1Event: number, useSeatingPlan: boolean) => {
    return requestWrapper<GlobalStatusCodesEnum, P1Event>(
        axios.patch(`${urlPrefix}/${p1Event}`, {
            is_mapped_seating_plans: useSeatingPlan
        }),
    );
}

export const setP1EventPriceIncludesHotel = (p1Event: number, hotelIncludedInPrice: boolean) => {
  return requestWrapper<GlobalStatusCodesEnum, P1Event>(
      axios.patch(`${urlPrefix}/${p1Event}`, {
        use_price_ticket_hotel: hotelIncludedInPrice
      }),
  );
}
