import {useState, Dispatch, SetStateAction, useEffect} from 'react';
import {useSearchParams} from "react-router-dom";
import {HttpSuccessResponse} from '../@api/Responses/HttpSuccessResponse';
import {GlobalStatusCodesEnum} from '../enums/ResponseStatuses/GlobalStatusCodesEnum';
import {HttpFailureResponse} from '../@api/Responses/HttpFailureResponse';
import {PaginationResponseData} from '../@interfaces/Response/PaginationResponseData';
import {PaginationParams} from '../@interfaces/PaginationParams';
import {PAGINATION_PER_PAGE_OPTION_DEFAULT} from "../constants/Constants";
import {FormikValues} from "formik";
import _ from 'lodash';

export type TablePagination = {
    page: number;
    totalItems: number;
    lastPage: number;
    perPage: number;
}

export type Pagination<T> = TablePagination & {
    filterValues: FormikValues;
    items: T[];
    reset: () => void;
    paginate: (params?: any, filters?: any) => Promise<void>;
    loadChunk: (page?: number, perPage?: number, filters?: any) => Promise<T[]>;
    setItems: Dispatch<SetStateAction<T[]>>;
    setTotalItems: Dispatch<SetStateAction<number>>;
    setCurrentPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
    setFilterValues: Dispatch<SetStateAction<any>>;
    isLoading: boolean;
    error: string;
}

export type PaginationRequest = FormikValues & {
  page: number;
  perPage: number;
}

const usePagination = <T>(
  loader: (params: PaginationParams<T>) => Promise<HttpSuccessResponse<GlobalStatusCodesEnum, PaginationResponseData<T>> | HttpFailureResponse<GlobalStatusCodesEnum, any>>,
  filtersInitial = {},
  useUrlParams = true
) : Pagination<T> => {
  const [urlParams, _setUrlParams] = useSearchParams();

  const filtersFromParams = {};
  Object.keys(filtersInitial).forEach((key) => {
      //@ts-ignore-next-line
      filtersFromParams[key] = urlParams.hasOwnProperty(key) ? filtersFromParams[key] : filtersInitial[key];
  });

  const [items, setItems] = useState<T[]>([]);
  const [params, _setParams] = useState<PaginationRequest>({
    page: urlParams?.has('page') ? 1 : -1,
    perPage: PAGINATION_PER_PAGE_OPTION_DEFAULT,
    ...filtersFromParams
  });

  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paramsSet, setParamsSet] = useState(false);
  const [error, setError] = useState('');

  const {page, perPage, ...filterValues} = params;

  const toUrlParams = (newValues: typeof filterValues) => {
    const filterValues: any = {};
    Object.entries(newValues).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        let newVal = value;
        if (!isNaN(Number(value))) {
          newVal = typeof value !== 'string' ? Number(value) : value;
        }
        filterValues[key] = newVal;
      }
    });
    return {
      page: String(page),
      perPage: String(perPage),
      ...filterValues,
    };
  }

  const setParams = (data: any) => {
    if (useUrlParams) {
      _setUrlParams(toUrlParams(data));
    } else {
      _setParams(data);
    }
  }

  const reset = () => {
    const defaultParams = {
      page: 1,
      perPage: PAGINATION_PER_PAGE_OPTION_DEFAULT,
      ...filtersInitial
    };
    if (!_.isEqual(defaultParams, params)) {
      setParams(defaultParams);
      setParamsSet(false);
    }
  };

  const setFilterValues = (newValues: typeof filtersInitial) => {
    setParams(toUrlParams({
      page: 1,
      perPage,
      ...newValues
    }));
    setParamsSet(false);
  }

  const setCurrentPage = (newValue: number) => {
    if (newValue !== page) {
      setParamsSet(false);
      setParams({
        ...params,
        page: String(newValue)
      });
    }
  }

  const setPerPage = (newValue: number) => {
    if (newValue !== perPage) {
      setParams({
        ...params,
        page: 1,
        perPage: newValue
      });
      setParamsSet(false);
    }
  }

  const loadChunk = async (chunkPage?: number, chunkPerPage?: number, filters?: typeof filtersInitial) => {
        setIsLoading(true);
        const requestParams = {
            page: chunkPage || params.page,
            perPage: chunkPerPage || params.perPage,
            ...(filters || params.filters)
        };
        const res = await loader(requestParams);
        setIsLoading(false);
        if (res instanceof HttpSuccessResponse) {
            return res.data.items;
        } else {
            return [];
        }
    }

  const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum, PaginationResponseData<T>>) => {
    setItems(res.data.items);
    setTotalItems(res.data.total_items);
    setLastPage(res.data.last_page);
  };

  const failureResponse = (res: HttpFailureResponse<GlobalStatusCodesEnum, any>) => {
    setItems([]);
    setCurrentPage(1);
    setTotalItems(0);
    setLastPage(0);
    setError(res.message);
  };

  const paginate = async (additionalParams: any = {}) => {
    setError('');
    setIsLoading(true);
    const requestParams = {page, perPage, ...additionalParams, ...filterValues};
    if (requestParams.hasOwnProperty('to_date') && requestParams.to_date) {
      requestParams.to_date = new Date(+requestParams.to_date);
    }
    if (requestParams.hasOwnProperty('from_date') && requestParams.from_date) {
      requestParams.from_date = new Date(+requestParams.from_date);
    }
    const res = await loader(requestParams);
    if (res instanceof HttpSuccessResponse) {
      successResponse(res);
    } else {
      failureResponse(res);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const filtersFromParams: any = {};

    Object.keys(filtersInitial).forEach((key) => {
      const urlValue = urlParams.get(key);
      if (urlValue !== undefined) {
        const isNum = urlValue && !isNaN(Number(urlValue));
        filtersFromParams[key] = isNum ? +urlValue : urlValue;
      }
    });

    const pageFromUrl = urlParams?.get('page');
    const perPageFromUrl = urlParams?.get('perPage');
    const newParams = useUrlParams ? {
      page: Math.max(pageFromUrl ? +pageFromUrl : 1, 1),
      perPage: perPageFromUrl ? +perPageFromUrl : PAGINATION_PER_PAGE_OPTION_DEFAULT,
      ...filtersFromParams
    } : {
      page: Math.max(params.page ? +params.page : 1, 1),
      perPage: params.perPage ? +params.perPage : PAGINATION_PER_PAGE_OPTION_DEFAULT,
      ...filterValues
    }

    if (!_.isEqual(newParams, params) || !paramsSet) {
      _setParams(newParams);
      if (!paramsSet) {
        setParamsSet(true);
      }
    }
  }, [params, paramsSet, filtersInitial, urlParams]);

  useEffect(() => {
    if (paramsSet) {
      paginate();
    }
  }, [paramsSet]);

  return {
    reset,
    paginate,
    loadChunk,
    items,
    setItems,
    page,
    setCurrentPage,
    totalItems,
    setTotalItems,
    lastPage,
    perPage,
    setPerPage,
    filterValues,
    setFilterValues,
    isLoading,
    error,
  };
};

export default usePagination;
