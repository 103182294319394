import React, {FC, useCallback, useEffect, useState} from 'react';
import {getSeries as fetchSeries} from '../../../../../@api/Series';
import {HttpSuccessResponse} from '../../../../../@api/Responses/HttpSuccessResponse';
import {P1Category} from '../../../../../models/Category';
import {get as fetchCategories} from '../../../../../@api/Categories';
import AppSelect from '../../../../../ui/Inputs/AppSelect/AppSelect';
import {EventPageFilters} from '../../EventsPage';
import {FormikFormChange} from '../../../../../@interfaces/FormikHelpers';
import {useFormikContext} from "formik";
import {P1CategorySeries} from "../../../../../models/P1/P1CategorySeries";

type Props = {
  filters: EventPageFilters,
  onFormChange?: FormikFormChange<EventPageFilters>
}
const P1EventCategoryFilter: FC<Props> = ({filters, onFormChange}) => {
  const {setFieldValue} = useFormikContext();

  const [series, setSeries] = useState<P1CategorySeries[]>([]);
  const [categories, setCategories] = useState<P1Category[]>([]);
  const [state, setState] = useState<{
    category: number,
    series: string,
    operator: string
  }>({category: filters.category_id ?? 0, series: filters.series ?? '', operator: filters.operator ?? ''});

  const setFilterValues = useCallback(<K extends keyof EventPageFilters>(name: K, value: EventPageFilters[K]): void => {
    onFormChange && onFormChange({
      ...filters,
      [name]: value
    });
  }, [onFormChange, filters]);

  const handleCategoryChange = (value: number) => {
    setState({
      ...state,
      category: +value
    });
    setFilterValues('category_id', +value);
  };

  const handleSeriesChange = useCallback((value: string) => {
    setState({
      ...state,
      series: value ?? ''
    });
    setFilterValues('series', value);
  }, [state, setFilterValues]);

  const handleOperatorChange = (value: string) => {
    setState({
      ...state,
      operator: value
    });
    setFilterValues('operator', value);
  };

  useEffect(() => {
    fetchCategories().then((res) => setCategories(res.data));
    fetchSeries().then((data) => {
      if (data instanceof HttpSuccessResponse) {
        setSeries(data.data);
      }
    });
  }, []);

  const {series: selectedSeries, category: selectedCategory, operator: selectedOperator} = state;


  useEffect(() => {
    if (series.length > 0 && selectedCategory !== 0 && selectedSeries !== '' && !series.find((s) => s.name === selectedSeries && s.p1_category_id === selectedCategory)) {
      setState({
        category: selectedCategory,
        series: '',
        operator: selectedOperator
      });
      setFieldValue('series', '');
    }
  }, [selectedCategory, selectedOperator, selectedSeries, series, setFieldValue])

  useEffect(() => {
    setState({
      category: filters.category_id === null ? 0 : filters.category_id ?? 0,
      series: filters.series === null ? '' : filters.series ?? '',
      operator: filters.operator ?? ''
    });
  }, [filters]);

  return (
      <>
        <div className="events-page__filters__component">
          <AppSelect
              placeholder={!selectedCategory ? 'All Categories' : 'Category'}
              name={'category_id'}
              changeCallback={handleCategoryChange}
              value={selectedCategory}
              items={[
                {value: 0, name: 'All Categories'},
                ...categories.map(c => ({
                  value: c.id,
                  name: c.name
                }))]}
          />
        </div>
        <div className="events-page__filters__component">
          <AppSelect
              placeholder={!selectedSeries ? 'All Series' : 'Series'}
              name={'series'}
              value={selectedSeries}
              changeCallback={handleSeriesChange}
              items={[
                {value: '', name: 'All Series'},
                ...series.filter(option => !selectedCategory || option.p1_category_id === selectedCategory).map(option => ({
                  value: option.name,
                  name: option.name
                }))]}
          />
        </div>
        <div className="events-page__filters__component">
          <AppSelect
              placeholder={!selectedOperator ? 'All Events' : 'Events'}
              name={'operator'}
              value={selectedOperator}
              changeCallback={handleOperatorChange}
              items={[
                {value: '', name: 'All Events'},
                {value: '=', name: 'Unmapped'},
                {value: '<>', name: 'Mapped'}
              ]}
          />
        </div>
      </>
  );
};

export default P1EventCategoryFilter;
