import "./AppTable.scss";

import React, {FC, useEffect} from "react";
import Table from "react-bootstrap/Table";
import {heightTableStyle} from "../../../helpers/SpecificStyles";
import AppLoader from "../../Loader/AppLoader/AppLoader";
import AppPagination from "../../Pagination/AppPagination/AppPagination";
import {Pagination} from "../../../helpers/UsePagination";

interface Props {
    columns: string[],
    pagination?: Pagination<any>,
    filterValues?: any,
    children: JSX.Element,
    isLoading?: boolean,
    withAutoHeight?: boolean
    customTableWrapperClass?: string
    noRecordsMessage?: string
}

const AppTable: FC<Props> = (
    {
        columns,
        pagination,
        children,
        isLoading,
        withAutoHeight = true,
        customTableWrapperClass = '',
        noRecordsMessage = 'No records found'
    }
) => {

    useEffect(() => {
        if (withAutoHeight) {
            const element = document.getElementsByClassName('app-paginated-table-wrapper')[0];

            (element as HTMLElement).style.height = heightTableStyle('app-paginated-table').height;
        }
    }, [withAutoHeight]);

    return (
        <div className="app-paginated-table-wrapper">
            <div
                className={`app-paginated-table ${customTableWrapperClass}`}>
                {
                    isLoading ?
                        <div className="position-absolute top-50 start-50">
                            <AppLoader/>
                        </div>
                        :
                        <Table>
                            <thead className={"stick-header-events-table"}>
                            <tr>
                                {
                                    columns.map((key, index) => (
                                        <th key={index}>{key}</th>
                                    ))
                                }
                            </tr>
                            </thead>
                            {pagination?.totalItems === 0 ? (
                                <tbody>
                                    <tr className={"events-page__table__tr "}>
                                        <td className={"text-center"} colSpan={columns.length}>
                                            <div className="mt-5">{noRecordsMessage}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>{children}</tbody>
                            )}
                        </Table>
                }
            </div>
            {pagination && <AppPagination pagination={pagination} />}
        </div>
    );
}

export default AppTable;
