import React, {FC, useCallback, useEffect} from "react";
import {Container, Tab, Tabs} from "react-bootstrap";
import "./EventsPage.scss";
import {heightTableStyle} from "../../../helpers/SpecificStyles";

import {useNavigate, useParams} from "react-router-dom";
import SkyboxEventsPage from "./tabs/SkyboxEventsPage";
import P1EventsPage from "./tabs/P1EventsPage";
import ACMilanEventsPage from "./tabs/ACMilanEventsPage";
import {EventsPageType} from "../../../enums/EventsPageTypeEnum";

export type EventPageFilters = {
  id?: string,
  name?: string,
  venue?: string,
  city?: string,
  performer?: string,
  category?: string,
  category_id?: number,
  series?: string,
  operator?: string,
  from_date: Date | null,
  to_date?: Date | null,
};

const EventsPage: FC = () => {
  const {tab} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const elements = document.getElementsByClassName('app-paginated-table-wrapper') as unknown as HTMLElement[];

    for (const element of elements) {
      element.style.height = heightTableStyle('app-paginated-table-wrapper', 0).height;
    }
  }, []);

  const navigateToTab = useCallback((newTab: string | null) => {
    navigate('/admin/events/' + newTab);
  }, [navigate]);

  useEffect(() => {
    if (!tab || (tab && !Object.values(EventsPageType).includes(tab as EventsPageType))) {
      navigateToTab(EventsPageType.SB);
    }
  }, [tab, navigateToTab]);

  return (
      <Container className={'admin-users-page events'}>
        <h1 className={'admin-users-page__title'}>Events</h1>
        <Tabs defaultActiveKey={tab} activeKey={tab} onSelect={navigateToTab}>
          <Tab title={'Skybox Events'} eventKey={EventsPageType.SB}>
            {tab === EventsPageType.SB && (<SkyboxEventsPage/>)}
          </Tab>
          <Tab title={'P1 Hospitality Events'} eventKey={EventsPageType.P1}>
            {tab === EventsPageType.P1 && (<P1EventsPage/>)}
          </Tab>
          <Tab title={'AC Milan Events'} eventKey={EventsPageType.ACMilan}>
            {tab === EventsPageType.ACMilan && (<ACMilanEventsPage/>)}
          </Tab>
        </Tabs>
      </Container>
  )
}

export default EventsPage;
